<template>
  <main class="sports">
    <section class="hero">
      <div v-for="section in links">
        <h2>{{ section.game }}</h2>
      </div>
    </section>
  </main>
</template>

<script>
// Components
import apiUrl from "@/utils/apiUrl";

export default {
  name: "sports",
  components: {},
  data() {
    return {
      links: [],
    };
  },
  methods: {},
  async mounted() {
    const url = `${apiUrl()}/scrape?url=https://mlbbox.me/mlb-2024-live-streams`;
    console.log({ url });
    const response = await fetch(url);
    const json = await response.json();
    this.links = json;
  },
};
</script>

<style lang="scss">
@import "../styles/mixins";
@import "../styles/vars";

// - All Sections
// -------------------------------------------
</style>
